import { Pipe, PipeTransform } from "@angular/core"
// import { extractMath } from "extract-math"
import { extractMath } from "./extractMath"
import katex from "katex"

@Pipe({ name: "katex" })
export class KatexPipe implements PipeTransform {
    transform(value: any): any {
        if (!value) return value

        const segments = extractMath(value)

        const allHtml = segments
            .map(seg => {
                if (seg.math) {
                    const el = document.createElement("span")
                    el.innerHTML = seg.raw
                    const raw = el.textContent

                    let katexElement
                    try {
                        katexElement = katex.renderToString(raw, {
                            displayMode: seg.type === "display"
                        })
                    } catch (e) {
                        katexElement = raw
                    }

                    return katexElement
                } else {
                    return seg.value
                }
            })
            .reduce((total, current) => {
                return (total += current)
            })

        return allHtml
    }
}
