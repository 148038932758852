import { Subject, Observable, forkJoin, throwError } from "rxjs"
import { map, timeout as tout, catchError } from "rxjs/operators"
import { BaseModel } from "./base.model"
import { HttpClient, HttpResponse, HttpParams, HttpHeaders, HttpRequest } from "@angular/common/http"
import { Router } from "@angular/router"
import { Injectable } from "@angular/core"

import { AuthService } from "../auth/auth.service"
import { ErroresService } from "../util/error/errores.service"
import { AppEnv } from "../conf/app_env"

export type BaseModelClass = typeof BaseModel
export type ModelOrArray = BaseModel | BaseModel[] //DEPRECATED
//DEPRECATED
//DEPRECATED

export class CountPromise<T> extends Promise<T> {
    public then<U>(fun: (x: T, y: number) => U | Promise<U>, rej: (e: any) => any = null): Promise<U> {
        if (!fun) return super.then(null, rej) // Por alguna razón, luego de hacer un super.then(f), llama nuevamente a then pero sin parámetros.

        const f = (a: T) => {
            const totalElements = a ? (a as any).totalElements : null
            if (a) delete (a as any).totalElements

            const res = fun(a, totalElements)
            if (res !== undefined && res !== null) return res

            if (a) return totalElements
        }

        return super.then(f, rej)
    }
}

@Injectable()
export class BaseService<T> {
    public tableName: string
    public singularTableName: string
    protected restQuery: string
    protected objectQuery: BaseModel
    protected httpVerb: HttpVerbs = HttpVerbs.GET
    protected baseURL: string
    protected canConcat: boolean
    protected ignoreModel: boolean = false
    protected modelClass: BaseModelClass | (() => BaseModelClass)
    protected ignoreCatch: boolean = false
    protected withParams: boolean
    protected countQuery: string
    protected oldQuery: string

    protected csvFileName: string

    public getDownloadLink = BaseModel => {
        return ""
    }

    public isFileLink = BaseModel => {
        return false
    }

    constructor(
        protected http: HttpClient,
        protected auth: AuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        this.restQuery = ""
        this.canConcat = true
        this.withParams = false
        this.baseURL = this.environment.endpoints.base
    }

    toQueryString = (object: any, nested: string = ""): string => {
        return Object.getOwnPropertyNames(object)
            .map(key => {
                if (object[key] instanceof Function) return ""

                if (object[key] instanceof Array) {
                    if (object[key].length == 0) object[key] = [null]

                    return object[key]
                        .map(o => {
                            if (!(o instanceof Object))
                                return nested ? nested + "[" + key + "][]=" + o : key + "[]=" + o

                            return this.toQueryString(o, nested ? nested + "[" + key + "][]" : key + "[]")
                        })
                        .filter(x => x)
                        .join("&")
                } else if (object[key] instanceof Object && !(object[key] instanceof Date)) {
                    return this.toQueryString(object[key], nested ? nested + "[" + key + "]" : key)
                } else {
                    const value = ("" + object[key]).replace("+", "%2B")

                    return nested ? nested + "[" + key + "]=" + value : key + "=" + value
                }
            })
            .filter(x => x)
            .join("&")
    }

    public getSingularTableName(): string {
        return this.singularTableName
    }

    public getTableName(): string {
        return this.tableName
    }

    public find(id: number, params: any = null): CountPromise<T> {
        return this.one(id).get(params)
    }

    public save(objectQuery: BaseModel, timeout = 30000): CountPromise<T> {
        return this.all().post(objectQuery, timeout)
    }

    public update(id: number, toUpdate: BaseModel): CountPromise<T> {
        return this.one(id).patch(toUpdate)
    }

    public remove(id: number): CountPromise<T> {
        return this.one(id).delete()
    }

    public where(params: any = null): CountPromise<T[]> {
        return this.all().get(params, true)
    }

    public wherePost(params: any = null) {
        return this.all().concatExtra("post").post(params, 120000)
    }

    public wherePostCount(params: any = null) {
        this.enableIgnoreModel()

        const countParams = {
            ...params,
            count: 1
        }

        return this.all()
            .concatExtra("post")
            .post(countParams, 120000)
            .then(count => {
                this.disableIgnoreModel()

                return count
            })
    }

    public whereCount$(params: any = null): Observable<[T[], number]> {
        const queryObs = this.all().get$(params)
        this.enableIgnoreModel()
        const countObs = this.all().get$({ ...params, count: 1 }) as any as Observable<number>
        this.disableIgnoreModel()

        return forkJoin(queryObs, countObs)
    }

    public find$(id: number, params: any = null): Observable<T> {
        return this.one(id).get$(params)
    }

    public save$(objectQuery: BaseModel, timeout = 30000): Observable<T> {
        return this.all().post$(objectQuery, timeout)
    }

    public update$(id: number, toUpdate: BaseModel): Observable<T> {
        return this.one(id).patch$(toUpdate)
    }

    public remove$(id: number): Observable<T> {
        return this.one(id).delete$()
    }

    public where$(params: any = null): Observable<T[]> {
        return this.all().get$(params, true)
    }

    public enableIgnoreCatch() {
        this.ignoreCatch = true
    }

    public disableIgnoreCatch() {
        this.ignoreCatch = false
    }

    public enableIgnoreModel() {
        this.ignoreModel = true
    }

    public disableIgnoreModel() {
        this.ignoreModel = false
    }

    public uploadFile(
        fieldname: string | any,
        file: File,
        xhr_upload: Subject<XMLHttpRequest>,
        fun: (response) => void,
        timeout: number = 30000
    ) {
        var xhr = new XMLHttpRequest()
        xhr_upload.next(xhr)
        let data: FormData = new FormData()
        if (typeof fieldname === "string") {
            data.append(fieldname, file)
        } else {
            for (let key in fieldname) {
                if (fieldname.hasOwnProperty(key)) {
                    data.append(key, fieldname[key])
                }
            }
        }

        xhr.timeout = timeout

        xhr.onreadystatechange = () => {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                if (xhr.getResponseHeader("Authorization")) {
                    this.auth.setToken(xhr.getResponseHeader("Authorization"))
                }

                fun(xhr.response)
            }
        }

        xhr.ontimeout = () => {
            //¿Debería redigir a algún lado?
            this.restQuery = ""
            this.countQuery = null
            this.canConcat = true
            this.httpVerb = HttpVerbs.GET
        }

        xhr.open("POST", this.baseURL + this.restQuery, true)
        xhr.setRequestHeader("Authorization", this.auth.getToken())
        xhr.send(data)
        this.restQuery = ""
        this.countQuery = null
        this.canConcat = true
        this.httpVerb = HttpVerbs.GET
    }

    public downloadPDF(link) {
        return this.http.get(link, { responseType: "blob" as "json" }).toPromise()
    }

    public getRequestOptions() {
        let header
        if (this.auth.getToken()) {
            header = new HttpHeaders({ Authorization: this.auth.getToken() })
        }

        let opts: { headers?: HttpHeaders; observe: "response"; responseType?: "json" }
        if (header) {
            opts = { headers: header, observe: "response" }
        } else {
            opts = { observe: "response" }
        }

        if (this.csvFileName) opts.responseType = "arraybuffer" as "json"

        return opts
    }

    public resetParams() {
        this.oldQuery = this.restQuery
        this.restQuery = ""
        this.withParams = false
        this.countQuery = null
        this.canConcat = true
        this.httpVerb = HttpVerbs.GET
    }

    public getHttpObservable({
        body,
        timeout = 30000
    }: {
        body: BaseModel
        timeout: number
    }): Observable<HttpResponse<any>> {
        let observable: Observable<HttpResponse<any>>

        const opts = this.getRequestOptions()

        if (body && body[this.singularTableName] && body[this.singularTableName].concatAttributeToChilds) {
            body[this.singularTableName].concatAttributeToChilds()
        } else if (body && body[this.singularTableName] && Array.isArray(body[this.singularTableName])) {
            body[this.singularTableName].forEach(obj => {
                if (obj && obj.concatAttributeToChilds) {
                    obj.concatAttributeToChilds()
                }
            })
        }

        switch (this.httpVerb) {
            case HttpVerbs.GET:
                observable = this.http.get<any>(this.baseURL + this.restQuery, opts)

                // if(this.countQuery){
                //     let countObservable: Observable<HttpResponse<any>> = this.http.get<any>(this.baseURL + this.countQuery, opts);

                //     observable = forkJoin(queryObservable, countObservable.pipe(map((res) => res.body))).pipe(map((val) => {
                //         (val[0] as any).count = val[1]
                //         return val[0];
                //     }));
                // }

                if (this.csvFileName) {
                    observable = observable.pipe(
                        map(response => {
                            ;(response as any).fileBlob = new Blob([response.body], { type: "text/csv" })
                            ;(response as any).fileBlobFileName = this.csvFileName
                            delete this.csvFileName

                            return response
                        })
                    )
                }
                break
            case HttpVerbs.POST:
                observable = this.http.post(this.baseURL + this.restQuery, body, opts).pipe(tout(timeout))
                break
            case HttpVerbs.PATCH:
                observable = this.http.patch(this.baseURL + this.restQuery, body, opts).pipe(tout(timeout))
                break
            case HttpVerbs.DELETE:
                observable = this.http.delete(this.baseURL + this.restQuery, opts).pipe(tout(timeout))
                break
            default:
                break
        }

        this.resetParams()

        return observable
    }

    public getHttpPromise({ body, timeout = 30000 }: { body: BaseModel; timeout: number }): Promise<HttpResponse<any>> {
        var promise: Promise<HttpResponse<any>>

        const opts = this.getRequestOptions()

        if (body && body[this.singularTableName] && body[this.singularTableName].concatAttributeToChilds) {
            body[this.singularTableName].concatAttributeToChilds()
        } else if (body && body[this.singularTableName] && Array.isArray(body[this.singularTableName])) {
            body[this.singularTableName].forEach(obj => {
                if (obj && obj.concatAttributeToChilds) {
                    obj.concatAttributeToChilds()
                }
            })
        }

        switch (this.httpVerb) {
            case HttpVerbs.GET:
                var observable: Observable<HttpResponse<any>> = this.http.get<any>(this.baseURL + this.restQuery, opts)

                if (this.countQuery) {
                    let countObservable: Observable<HttpResponse<any>> = this.http.get<any>(
                        this.baseURL + this.countQuery,
                        opts
                    )

                    observable = forkJoin(observable, countObservable.pipe(map(res => res.body))).pipe(
                        map(val => {
                            ;(val[0] as any).count = val[1]
                            return val[0]
                        })
                    )
                }

                if (this.csvFileName) {
                    observable = observable.pipe(
                        map(response => {
                            ;(response as any).fileBlob = new Blob([response.body], { type: "text/csv" })
                            ;(response as any).fileBlobFileName = this.csvFileName
                            delete this.csvFileName

                            return response
                        })
                    )
                }
                promise = observable.toPromise()
                break
            case HttpVerbs.POST:
                promise = this.http
                    .post(this.baseURL + this.restQuery, body, opts)
                    .pipe(tout(timeout))
                    .toPromise()
                break
            case HttpVerbs.PATCH:
                promise = this.http
                    .patch(this.baseURL + this.restQuery, body, opts)
                    .pipe(tout(timeout))
                    .toPromise()
                break
            case HttpVerbs.DELETE:
                promise = this.http
                    .delete(this.baseURL + this.restQuery, opts)
                    .pipe(tout(timeout))
                    .toPromise()
                break
            default:
                break
        }

        this.resetParams()

        return promise
    }

    // tanto el método all, one y concatExtra son protected para reforzar el hecho de que métodos REST extra
    // vayan subclaseados en los servicios hijos
    protected concatExtra(route: string, id: number = null) {
        this.restQuery += "/" + route + (id ? "/" + id : "")

        return this
    }

    protected all() {
        this.restQuery = "/" + this.tableName

        return this
    }

    protected one(id: number) {
        this.restQuery = "/" + this.tableName + "/" + id

        return this
    }

    public getCommon<U>(
        params: any = null,
        count: boolean = false,
        timeout: number = 30000,
        queryFun: (params: { body?: BaseModel; timeout: number }) => U
    ) {
        let otherParams = ""
        if (params) {
            this.withParams = true
            otherParams = "?" + this.toQueryString(params)
            this.csvFileName = params.to_csv
        }

        this.restQuery += otherParams
        if (count) {
            this.countQuery = this.restQuery + (params ? "&" : "?") + "count=1"
        }
        this.canConcat = false
        this.httpVerb = HttpVerbs.GET

        return queryFun({ timeout })
    }

    public get(params: any = null, count: boolean = false, timeout: number = 30000) {
        return this.getCommon(params, count, timeout, this.getPromise)
    }

    public get$(params: any = null, count: boolean = false, timeout: number = 30000) {
        return this.getCommon(params, count, timeout, this.getObservable)
    }

    public postCommon<U>(
        objectQuery: BaseModel = new BaseModel(),
        timeout: number = 30000,
        queryFun: (params: { body?: BaseModel; timeout: number }) => U
    ) {
        let body = new BaseModel()
        if (Array.isArray(objectQuery)) {
            body[this.singularTableName] = objectQuery.map(oq => oq.clone())
        } else if (
            objectQuery.constructor != BaseModel &&
            !BaseModel.prototype.isPrototypeOf((objectQuery as any).constructor.prototype)
        ) {
            body = objectQuery
        } else {
            body[this.singularTableName] = objectQuery.clone()
        }
        this.canConcat = false
        this.httpVerb = HttpVerbs.POST

        return queryFun({ body, timeout })
    }

    public post(objectQuery: BaseModel = new BaseModel(), timeout: number = 30000) {
        return this.postCommon(objectQuery, timeout, this.getPromise)
    }

    public post$(objectQuery: BaseModel = new BaseModel(), timeout: number = 30000) {
        return this.postCommon(objectQuery, timeout, this.getObservable)
    }

    public patchCommon<U>(
        toUpdate: BaseModel = new BaseModel(),
        timeout: number = 30000,
        queryFun: (params: { body?: BaseModel; timeout: number }) => U
    ) {
        let body = new BaseModel()
        if (
            toUpdate.constructor != BaseModel &&
            !BaseModel.prototype.isPrototypeOf((toUpdate as any).constructor.prototype)
        ) {
            body = toUpdate
        } else {
            body[this.singularTableName] = toUpdate.clone()
        }
        this.canConcat = false
        this.httpVerb = HttpVerbs.PATCH

        return queryFun({ body, timeout })
    }

    public patch(toUpdate: BaseModel = new BaseModel(), timeout: number = 30000) {
        return this.patchCommon(toUpdate, timeout, this.getPromise)
    }

    public patch$(toUpdate: BaseModel = new BaseModel(), timeout: number = 30000) {
        return this.patchCommon(toUpdate, timeout, this.getObservable)
    }

    public deleteCommon<U>(
        params: any = null,
        timeout: number = 30000,
        queryFun: (params: { body?: BaseModel; timeout: number }) => U
    ) {
        this.canConcat = false
        this.httpVerb = HttpVerbs.DELETE

        return queryFun({ timeout })
    }

    public delete(params: any = null, timeout: number = 30000) {
        return this.deleteCommon(params, timeout, this.getPromise)
    }

    public delete$(params: any = null, timeout: number = 30000) {
        return this.deleteCommon(params, timeout, this.getObservable)
    }

    public getObservable = ({ body, timeout }: { body?: BaseModel; timeout: number }) => {
        type TandTArray = T & T[]

        const ignoreCatch = this.ignoreCatch
        const ignoreModel = this.ignoreModel

        const obs = () =>
            this.getHttpObservable({ body, timeout }).pipe(
                map(response => {
                    return this.processResponse(response, ignoreModel)
                }),
                catchError(response => {
                    return this.handleThenCatch(response, null, throwError, null, ignoreCatch)
                })
            ) as Observable<TandTArray>

        this.restQuery = ""
        this.disableIgnoreCatch()

        return obs()
    }

    public getPromise = ({ body, timeout }: { body?: BaseModel; timeout: number }) => {
        type TandTArray = T & T[]

        const ignoreCatch = this.ignoreCatch
        const ignoreModel = this.ignoreModel

        const pr = () =>
            new CountPromise<TandTArray>((resolve, reject) => {
                let promise: Promise<HttpResponse<any>>
                promise = this.getHttpPromise({ body, timeout })

                this.restQuery = ""
                let promiseNumber = promise.then(response => {
                    return this.processResponse(response, ignoreModel, resolve)
                })

                promiseNumber = promiseNumber.catch(response => {
                    this.handleThenCatch(response, resolve, reject, pr, ignoreCatch)
                })

                //return promiseNumber;
            })

        this.disableIgnoreCatch()

        return pr()
    }

    private processResponse(response, ignoreModel, fun = obj => obj) {
        type TandTArray = T & T[]

        if (response.status == 204) {
            this.restQuery = ""

            return fun([] as any)
        } else {
            // Seteo el token de autorización si el servidor me respondió uno nuevo
            if (response.headers.has("Authorization") && this.auth.isLoggedIn()) {
                this.auth.setToken(response.headers.get("Authorization"))
            }

            if (response["fileBlob"]) {
                const a = document.createElement("a")
                a.download = response["fileBlobFileName"]
                a.href = window.URL.createObjectURL(response["fileBlob"])
                a.click()

                return
            }

            let obj = response.body

            if (ignoreModel) {
                this.restQuery = ""
                if (obj instanceof Array) {
                    Object.defineProperty(obj, "totalElements", {
                        enumerable: false,
                        configurable: true,
                        writable: false,
                        value: (response as any).count
                    })
                }
                return fun(obj) // , (response as any).count);
            }

            let modelClass = this.modelClass
            if (typeof modelClass === "function" && !BaseModel.prototype.isPrototypeOf(modelClass.prototype)) {
                modelClass = (modelClass as Function)()
            }

            const fn = (from: any, to: BaseModel) => {
                for (const key in from) {
                    if (from[key] instanceof Array) {
                        let type = (Reflect as any).getMetadata("design:type", to, key)
                        if (typeof type === "function" && !BaseModel.prototype.isPrototypeOf(type.prototype)) {
                            type = type()
                        }

                        to[key] = from[key].map(v => {
                            if (v instanceof Object) {
                                if (!type) {
                                    return v
                                    // throw new TypeError(
                                    //     `El objeto de la API posee un arreglo de objetos, pero el modelo no lo esperaba. (${key} en ${to.className})`
                                    // )
                                }

                                const arrObj = new type()

                                fn(v, arrObj)

                                return arrObj
                            } else if (v instanceof Array) {
                                throw new TypeError("Un arreglo dentro de otro arreglo no está soportado actualmente")
                            } else {
                                if (type) {
                                    // throw new TypeError(
                                    //     "El objeto de la API posee un arreglo de tipos primitivos, sin embargo el modelo espera un objeto"
                                    // )
                                }

                                return v
                            }
                        })
                    } else if (from[key] instanceof Object) {
                        let type = (Reflect as any).getMetadata("design:type", to, key)

                        if (typeof type === "function" && !BaseModel.prototype.isPrototypeOf(type.prototype)) {
                            type = type()
                        }

                        if (!type) {
                            // throw new TypeError(
                            //     `El objeto de la API posee un objeto, pero el modelo no lo esperaba. (${key} en ${to.className})`
                            // )
                            to[key] = from[key]
                            continue
                        }

                        const objType = new type()
                        fn(from[key], objType)

                        to[key] = objType
                    } else {
                        to[key] = from[key]
                    }
                }
            }

            if (obj instanceof Array) {
                obj = obj.map(o => {
                    const fullObj = new (<any>modelClass)()
                    fn(o, fullObj)

                    return fullObj
                })

                Object.defineProperty(obj, "totalElements", {
                    enumerable: false,
                    configurable: true,
                    writable: false,
                    value: (response as any).count
                })
            } else {
                const fullObj = new (<any>modelClass)()
                fn(obj, fullObj)
                obj = fullObj
            }
            this.restQuery = ""

            return fun(obj as TandTArray)
        }
    }

    handleThenCatch = (response, fun = null, reject = null, promiseFun = null, ignoreCatch) => {
        if (response.status == 401) {
            this.auth.setRedirectUrl(this.router.url)
            this.router.navigate([""])
            this.auth.logout()
        } else if (!ignoreCatch) {
            // TODO (efrias): setResponse nunca resuelve a true, revisar las ocasiones donde hace sentido redirigir a errores (quizás sea solo en 404)
            this.errores.setResponse(response).then(resp => {
                if (resp) {
                    this.router.navigate(["errores"])
                } else {
                    return reject(response)
                }
            })
        } else {
            return reject(response)
        }
    }
}

export enum HttpVerbs {
    GET = 0,
    POST = 1,
    PATCH = 2,
    DELETE = 3,
    ALL = 4
}
