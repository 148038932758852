import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { Excel } from "./excels.model"
import { PuntajeAuthService } from "./puntaje_auth.service"
import { PuntajeBaseService } from "./puntaje_base.service"

@Injectable()
export class Excels extends PuntajeBaseService<Excel> {
    tableName = "excels"
    singularTableName = "excel"
    modelClass = Excel

    constructor(
        protected http: HttpClient,
        protected auth: PuntajeAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public fromData(params: any) {
        return this.all().concatExtra("from_data").post(params, 300000)
    }

    /**
     * Descarga un archivo excel
     * @param response Respuesta de la API
     * @param filename Nombre del archivo
     * @returns void
     */
    public downdloadExcel(response, filename: string) {
        const decodedExcel = atob(response["excel_content_encoded"])
        let binaryData = new Uint8Array(decodedExcel.length)
        binaryData = binaryData.map((d, i) => decodedExcel.charCodeAt(i))

        const a = document.createElement("a")
        a.download = filename
        a.href = window.URL.createObjectURL(
            new Blob([binaryData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        )
        a.click()
    }
}
