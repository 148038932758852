import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Instrumento, Instrumentos, InstrumentoPregunta } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GeneradorInstrumento, GeneradorInstrumentos, Pregunta, Preguntas } from "@puntaje/nebulosa/api-services"

declare let $

@Component({
    selector: "generar-instrumento-lista-preguntas",
    templateUrl: "generar_instrumento_lista_preguntas.component.html",
    styleUrls: ["generar_instrumento_lista_preguntas.component.scss"]
})
export class GenerarInstrumentoListaPreguntasComponent implements OnInit {
    config: typeof config
    tipo_instrumento: string = ""
    instrumento: Instrumento
    preguntas: Pregunta[]
    instrumentoPreguntas: InstrumentoPregunta[]
    preguntasIds: string
    preguntasIdsArray: string[]
    plataforma_id: number
    generadores: GeneradorInstrumento[]
    generador_id: number
    asignatura_id: number
    generador_selected: GeneradorInstrumento = null
    searching: boolean = false
    errors: string[] = []
    tiempo: number
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutPreguntas", { static: true }) loadingLayoutPreguntas: LoadingLayoutComponent
    preguntasAreVisible: boolean = false
    preguntasNotPlatform: Pregunta[]
    errorPreguntasNotPlatform: boolean = false

    constructor(
        protected instrumentosService: Instrumentos,
        protected router: Router,
        protected generadorService: GeneradorInstrumentos,
        protected preguntasService: Preguntas
    ) {
        this.config = config
    }

    @Output() instrumentoChange: EventEmitter<Instrumento> = new EventEmitter<Instrumento>()

    ngOnInit() {
        this.plataforma_id = config.plataforma.id
        this.generadorService
            .where({
                tipo_instrumento: { tipo_instrumento: this.config.plataforma.evaluacionTipos },
                asignatura_plataforma: { plataforma_id: this.plataforma_id },
                with_asignatura: true,
                with_tipo_instrumento: true
            })
            .then((generadores: GeneradorInstrumento[]) => {
                this.generadores = generadores
                this.loadingLayout.ready()
                this.loadingLayoutPreguntas.ready()
            })
    }

    /*guardar() {
        this.errors = [];

        if (this.generador_selected && this.lote) {
            if (this.lote.asignatura['id'] !== this.generador_selected["asignatura"]["id"]) {
                this.errors.push("La asignatura del lote debe ser la misma que la asignatura del generador");
            } else {
                let query_obj = {
                    lote_id: this.lote_id,
                    generador_instrumento_id: this.generador_id,
                    asignatura_id: this.asignatura_id
                }
                this.instrumentosService.desdeLote(query_obj).then((response: Instrumento) => {
                this.instrumentoChange.emit(response);
            });
            }
        }

    }*/

    updateValues(generador: GeneradorInstrumento) {
        this.generador_selected = generador
        this.generador_id = generador.id
        this.asignatura_id = generador["asignatura_plataforma"].asignatura_id
    }

    getPreguntas() {
        if (this.preguntasIds) {
            this.preguntas = null
            this.searching = true
            this.errorPreguntasNotPlatform = false
            this.preguntasIdsArray = this.preguntasIds.trim().split(",")

            let params = {
                with_grupo_pregunta: 1,
                with_clasificaciones: 1,
                with_clasificaciones_with_clasificacion_tipo: 1,
                pregunta: {
                    id: this.preguntasIdsArray
                },
                include: "plataformas"
            }

            this.loadingLayoutPreguntas.loading = true
            this.preguntasService.where(params).then((response: Pregunta[]) => {
                this.preguntas = response
                this.instrumentoPreguntas = []
                this.preguntas.forEach((p, index) => {
                    let ip = new InstrumentoPregunta()
                    ip.orden = index
                    this.instrumentoPreguntas.push(ip)
                })
                this.tiempo = this.preguntas.length * 2
                this.searching = false
                this.preguntasNotPlatform = this.preguntas.filter(p => !p.isFromPlatform())
                this.errorPreguntasNotPlatform = this.preguntasNotPlatform && this.preguntasNotPlatform.length > 0

                this.preguntasAreVisible = true
                this.loadingLayoutPreguntas.ready()
            })
        }
    }

    generar() {
        if (this.generador_selected && this.preguntas && this.preguntas.length > 0) {
            let preguntasIdsFinalArray = this.preguntas.map((p: Pregunta) => p.id)

            let query_obj = {
                preguntas_id: preguntasIdsFinalArray,
                generador_instrumento_id: this.generador_id,
                asignatura_id: this.asignatura_id,
                tiempo: this.tiempo
            }
            this.instrumentosService.desdeListaPreguntas(query_obj).then((response: Instrumento) => {
                this.router.navigate(["/instrumentos/" + response.id])
            })
        }
    }
}
